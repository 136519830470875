<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item required label="Description">
          <a-textarea
            placeholder="The description of Reward / Punishment"
            v-model="dataRewardPunishment.description"
            @change="(e) => handleChange(e.target.value, 'description')"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item required label="Score">
          <a-input-number v-model="dataRewardPunishment.score" size="large" placeholder="The score of Reward / Punishment" @change="(e) => handleChange(e, 'score')" :min="1" :max="100"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item required label="Type">
          <a-select
            size="large"
            class="w-100"
            :value="dataRewardPunishment.type"
            @change="(e) => handleChange(e, 'type')"
          >
            <a-select-option value="reward"> Reward </a-select-option>
            <a-select-option value="punishment">
              Punishment
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataRewardPunishment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      console.log('child handleChange =>', payload)
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>
</style>
